<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Ανανέωση
            </h2>

            <br>
            <p class="display-1">
              Ανανέωση άδειας οδήγησης ερασιτεχνικής ή επαγγελματικής
            </p><p class="subtitle-1">
              Αρμόδια Υπηρεσία:<br><br>
              Η Υπηρεσία Μεταφορών & Επικοινωνιών της Περιφερειακής ενότητας ή του Περιφερειακού διαμερίσματος
              της Περιφέρειας του τόπου κατοικίας του ενδιαφερόμενου.<br><br>
              Προϋποθέσεις:<br><br>
              Η ανανέωση γίνεται χωρίς θεωρητική και πρακτική εξέταση, έπειτα από ιατρική εξέταση, κατά την οποία πρέπει
              να κριθεί ότι ο ενδιαφερόμενος πληροί τις ελάχιστες απαιτούμενες προϋποθέσεις υγείας.
              Σε περίπτωση που ο αιτών δεν είναι Έλληνας υπήκοος προσκομίζεται φωτοαντίγραφο της άδειας διαμονής ή παραμονής,
              ή άδειας εργασίας, η οποία πρέπει να έχει εκδοθεί έξι (6) μήνες πριν την υποβολή της αίτησης για άδεια οδήγησης.<br><br>
              Δικαιολογητικά:
              <br><br>
              <ul>
                <li>Αίτηση εκτύπωσης</li>
                <li>Αίτηση ανανέωσης</li>
                <li>Παράβολο ανανέωσης των 60 € κωδ.0023   (<a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-παράβολο)</a></li>
                <li>Παράβολο εκτύπωσης των 30 € κωδ.0028   (<a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-παράβολο)</a></li>
                <li>Παράβολο χαρτόσημο των 18 € κωδ.0065   (<a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-παράβολο)</a></li>
                <li>Πιστοποιητικά Ιατρών Οφθαλμίατρου & Παθολόγου</li>
                <li>4 πρόσφατες έγχρωμες φωτογραφίες τύπου διαβατηρίου</li>
                <li>Φωτοαντίγραφο της αστυνομικής ταυτότητας ή διαβατηρίου</li>
                <li>Η προς ανανέωση άδεια οδήγησης</li>
                <li>Φάκελος</li>
                <br>
              </ul>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/sideServices/Ανανέωση διπλώματος.jpg" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container>
        <p class="display-1">
          Ισχύς<br><br>
        </p><p class="subtitle-1">
          Οι άδειες οδήγησης για τις κατηγορίες ΑΜ, Α1, Α2, Α, Β1, Β, και ΒΕ έχουν διοικητική ισχύ δέκα πέντε (15) έτη,
          από την ημέρα της επιτυχούς εξέτασης προσόντων και συμπεριφοράς για τη χορήγηση της συγκεκριμένης κατηγορίας
          ή από την ημέρα της ανανέωσής της, κατά περίπτωση, και όχι πέραν της συμπλήρωσης της ηλικίας των 65 ετών του
          κατόχου της άδειας.<br><br>
          Οι άδειες οδήγησης για τις κατηγορίες C1, C1E, C, CE, D1, D1E, D και DE έχουν διοικητική ισχύ πέντε (5) έτη,
          από την ημέρα της επιτυχούς εξέτασης προσόντων και συμπεριφοράς για τη χορήγηση της συγκεκριμένης κατηγορίας
          ή από την ημέρα της ανανέωσής της, κατά περίπτωση, και όχι πέραν της συμπλήρωσης της ηλικίας των 65 ετών του
          κατόχου της άδειας.<br><br>
          Η διοικητική ισχύς των αδειών οδήγησης που ανανεώνονται πριν από τη συμπλήρωση της ηλικίας των 65 ετών, για όλες τις κατηγορίες αδειών οδήγησης, δεν επιτρέπεται να έχει ημερομηνία λήξης μεγαλύτερη του 68ου έτους της ηλικίας του κατόχου
          της άδειας. Από τη συμπλήρωση της ηλικίας των 65 ετών και μέχρι τη συμπλήρωση των 80 ετών, η διοικητική ισχύς όλων των κατηγοριών αδειών οδήγησης δεν επιτρέπεται να υπερβεί τα τρία (3) έτη, από την ημέρα της επιτυχούς εξέτασης προσόντων
          και συμπεριφοράς για τη χορήγηση οποιασδήποτε κατηγορίας ή από την ημέρα της ανανέωσης της κατηγορίας, κατά περίπτωση.<br><br>
          Η διοικητική ισχύς των αδειών οδήγησης που ανανεώνονται πριν από τη συμπλήρωση της ηλικίας των 80 ετών, για όλες τις κατηγορίες αδειών οδήγησης, δεν επιτρέπεται να έχει ημερομηνία λήξης μεγαλύτερη του 82ου έτους της ηλικίας του κατόχου
          της άδειας.<br><br>
          Μετά τη συμπλήρωση της ηλικίας των 80 ετών, η διοικητική ισχύς όλων των κατηγοριών αδειών οδήγησης δεν επιτρέπεται
          να υπερβεί τα δύο (2) έτη και απαιτείται εξέταση απο ιατρούς των ειδικοτήτων:
          Παθολόγου, Οφθαλμιάτρου, Ωτορινολαρυγγολόγου & Νευρολόγου ή Ψυχίατρου τουλάχιστον, σύμφωνα με το αρθ. 74 παρ.1
          του Ν.4413/2016 (ΦΕΚ 148 Α’).<br><br>
          Οι οδηγοί που έχουν συμπληρώσει το 80ο έτος της ηλικίας του,εξετάζονται απο 4 ιατρούς (παθολόγο-οφθαλμίατρο-νευρολόγο και ω.ρ.λ.).Απαιτούνται (6)φωτογραφίες αντι για (4).<br><br>
        </p>
      </v-container>
    </section>
    <section>
      <v-container>
        <div class="text-center font-weight-bold">
          <v-text>
            Τα παράβολα εκδίδονται απο την εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> και τα αναλαμβάνει και η σχολή να τα εκδώσει χωρίς επιβάρυνση!
          </v-text>
        </div>
      </v-container>
    </section>
    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            cols="6"
            md="6"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      pdfs: [
        ['Εκτύπωση Αίτησης', '../static/εκτύπωση-αίτησης.pdf'],
        ['Αντικατάσταση Άδειας Οδήγησης', '../static/Μ-ΤΑΟ13-Ανανέωση-διπλώματος-οδήγησης.pdf']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
